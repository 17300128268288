import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';
import Notifications from './Notifications';

function Header() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { getTotalItems } = useCart();
  const isMobile = useMediaQuery('(max-width:500px)');
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const menuItems = user ? [
    { text: 'Cafeterias', link: '/cafeterias' },
    { text: `Cart (${getTotalItems()})`, link: '/cart' },
    { text: 'Orders', link: '/orders' },
    { text: 'Logout', onClick: handleLogout }
  ] : [
    { text: 'Login', link: '/login' },
    { text: 'Register', link: '/register' }
  ];

  const renderMenuItems = () => (
    menuItems.map((item, index) => (
      <ListItem button key={index} component={item.link ? Link : 'button'} to={item.link} onClick={item.onClick || (() => setDrawerOpen(false))}>
        <ListItemText primary={item.text} />
      </ListItem>
    ))
  );

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Typography variant="h6" component={Link} to="/" style={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}>
          Cafeteria Customer Portal
        </Typography>
        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <Box sx={{ width: 250 }} role="presentation">
                <List>
                  {renderMenuItems()}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          <Box display="flex" alignItems="center">
            {user && <Notifications />}
            {menuItems.map((item, index) => (
              <Button key={index} color="inherit" component={item.link ? Link : 'button'} to={item.link} onClick={item.onClick}>
                {item.text}
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;