import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  Snackbar, IconButton, List, ListItem, ListItemText, 
  Typography, Badge, Drawer, Box, Alert
} from '@mui/material';
import { Close as CloseIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import { createWebSocket, WS_ENDPOINTS } from '../config/api';
import { useAuth } from '../contexts/AuthContext';

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [latestNotification, setLatestNotification] = useState(null);
  const [error, setError] = useState(null);
  const { token } = useAuth();

  const handleNewNotification = useCallback((message) => {
    setNotifications(prev => [message, ...prev]);
    setLatestNotification(message);
    setShowSnackbar(true);
  }, []);

  useEffect(() => {
    if (!token) return;

    const socket = createWebSocket(`${WS_ENDPOINTS.NOTIFICATIONS}`, token);

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'notification') {
          handleNewNotification(data.message);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
        setError('Error receiving notifications. Please refresh the page.');
      }
    };

    socket.onerror = (event) => {
      console.error('WebSocket error:', event);
      setError('Error connecting to notification service. Please refresh the page.');
    };

    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, [token, handleNewNotification]);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSnackbarClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  }, []);

  const notificationCount = useMemo(() => notifications.length, [notifications]);

  const NotificationsList = useMemo(() => (
    <List>
      {notifications.map((notification, index) => (
        <ListItem key={index} divider>
          <ListItemText 
            primary={notification.title} 
            secondary={
              <>
                <Typography component="span" variant="body2" color="text.primary">
                  {notification.body}
                </Typography>
                <Typography component="span" variant="caption" display="block" color="text.secondary">
                  {new Date(notification.timestamp).toLocaleString()}
                </Typography>
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  ), [notifications]);

  return (
    <>
      <IconButton color="inherit" onClick={handleDrawerOpen} aria-label="notifications">
        <Badge badgeContent={notificationCount} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
        <Box sx={{ width: 300, p: 2 }}>
          <Typography variant="h6" gutterBottom>Notifications</Typography>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {notificationCount === 0 ? (
            <Typography variant="body2" color="text.secondary">No new notifications</Typography>
          ) : (
            NotificationsList
          )}
        </Box>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={latestNotification ? `${latestNotification.title}: ${latestNotification.body}` : "New notification"}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}

export default React.memo(Notifications);