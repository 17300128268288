import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline, CircularProgress, Container, Box } from '@mui/material';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import Header from './components/Header';
import ErrorBoundary from './components/ErrorBoundary';

// Lazy load components
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const CafeteriaList = lazy(() => import('./pages/CafeteriaList'));
const MenuList = lazy(() => import('./pages/MenuList'));
const Cart = lazy(() => import('./pages/Cart'));
const OrderHistory = lazy(() => import('./pages/OrderHistory'));
const NotFound = lazy(() => import('./components/NotFound'));

// Create a responsive theme
const theme = responsiveFontSizes(createTheme({
  // Add your custom theme options here
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  // Add more customizations as needed
}));

// PrivateRoute component
const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <CircularProgress />;
  }
  
  return user ? children : <Navigate to="/login" />;
};

// Loading component
const Loading = () => (
  <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
    <CircularProgress />
  </Box>
);

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <CartProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Header />
                <Box component="main" sx={{ flexGrow: 1, mt: 2 }}>
                  <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Suspense fallback={<Loading />}>
                      <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/cafeterias" element={
                          <PrivateRoute>
                            <CafeteriaList />
                          </PrivateRoute>
                        } />
                        <Route path="/cafeteria/:id" element={
                          <PrivateRoute>
                            <MenuList />
                          </PrivateRoute>
                        } />
                        <Route path="/cart" element={
                          <PrivateRoute>
                            <Cart />
                          </PrivateRoute>
                        } />
                        <Route path="/orders" element={
                          <PrivateRoute>
                            <OrderHistory />
                          </PrivateRoute>
                        } />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </Suspense>
                  </Container>
                </Box>
              </Box>
            </Router>
          </ThemeProvider>
        </CartProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;