// customer-frontend/src/config/api.js

const API_BASE_URL = 'https://api.ecodish365.com';
const WS_BASE_URL = 'wss://api.ecodish365.com';

export const API_ENDPOINTS = {
  REGISTER: `${API_BASE_URL}/customer/register/`,
  LOGIN: `${API_BASE_URL}/token/`,
  REFRESH_TOKEN: `${API_BASE_URL}/token/refresh/`,
  USER_PROFILE: `${API_BASE_URL}/profile/`,
  CAFETERIAS: `${API_BASE_URL}/cafeterias/`,
  MENUS: `${API_BASE_URL}/menuitems/`,
  CUSTOMER_ORDERS: `${API_BASE_URL}/orders/`,
  MENUCATEGORIES: `${API_BASE_URL}/menucategories/`,
  SEND_NOTIFICATION: `${API_BASE_URL}/send-notification/`,
};

export const WS_ENDPOINTS = {
  CUSTOMER_ORDERS: `${WS_BASE_URL}/customer/orders/`,
  NOTIFICATIONS: `${WS_BASE_URL}/notifications/`,
};

export const createWebSocket = (endpoint, token) => {
  return new WebSocket(`${endpoint}?token=${token}`);
};